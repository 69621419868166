<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-coin-wallet-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">انتقال درامد به کیف پول</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <div class="flex flex-wrap">
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-4/12 sm:pl-3">
          <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
            <div>موجودی گروه :</div>
            <div class="text-xl text-left mt-4">
              {{ $number.format(group.info.account_income + group.info.ad_income) }} تومان
            </div>
          </div>
        </div>
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 mt-5 sm:pr-3 sm:mt-0">
          <form @submit.prevent="submit">
            <MnrSelect
              v-model="wallet"
              :options="admins"
              oValue="id"
              oText="first_name"
              oHint="username"
              title="کیف پول مقصد"
            />
            <Button type="submit" class="mt-5" color="cyan">انتقال</Button>
          </form>
        </div>
        <div class="mt-10 w-full">
          <h2 class="text-xl font-bold">آخرین تراکنش ها</h2>
          <div class="flex flex-wrap p-3 bg-teal-600 text-center text-teal-50 mt-5">
            <div class="w-6/12 md:w-2/12">ID</div>
            <div class="w-6/12 md:w-2/12">نام کاربری</div>
            <div class="w-6/12 md:w-3/12">مقصد</div>
            <div class="w-4/12 md:w-2/12">مبلغ (تومان)</div>
            <div class="w-4/12 md:w-3/12">زمان</div>
          </div>
          <div
            v-for="item in last"
            :key="item.id"
            class="flex flex-wrap p-3 odd:bg-blueGray-100 text-center"
          >
            <div class="w-6/12 md:w-2/12">{{ item.user.uid }}</div>
            <div class="w-6/12 md:w-2/12" dir="ltr">@{{ item.user.username }}</div>
            <div class="w-6/12 md:w-3/12">
              {{ item.user.first_name }} {{ item.user.last_name }}
            </div>
            <div class="w-4/12 md:w-2/12">{{ $number.format(item.amount) }}</div>
            <div class="w-4/12 md:w-3/12">{{ item.updated_at.fa }}</div>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrSelect,
    ErrorBox,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
      last: [],
      admins: [],
      errors: [],
      wallet: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-income/transfer/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.last = response.data.last;
          $this.admins = response.data.admins;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      let $this = this;
      $this.sending = true;
      $this.$axios
        .put("/api/group-income/transfer/" + $this.$route.params.groupId, {
          id: $this.$store.state.user.id,
          wallet: $this.wallet,
          // date: $this.date,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "GroupOwnerSettingIncome",
            params: {
              id: $this.$store.state.user.id,
              groupId: $this.$route.params.groupId,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>
